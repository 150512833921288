import { Checkbox  } from "@mui/material";
import { Text } from "@react-pdf/renderer"; 

function EditableCheck(props){
    let component;
    if(props.isPDF){
        component = <Text>{props.data.fieldValue}</Text>
    }else{
        component = 
            <Checkbox  
                onChange={props.onChange} 
                id={props.data.fieldId} 
                label={props.data.fieldLabel} 
                variant="standard" 
                checked={props.data.value==='t'?true:false}
            />
    }

    return (
        component
    );
}

export default EditableCheck;