import React, { useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoicePDF from './InvoicePDF';
import {Button} from '@mui/material/';


const Download = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false)

    const timeout = setTimeout(() => {
      setShow(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [props]);

  let invoiceData = props.invoiceState;

  return (
    <div className={'download-pdf ' + (!show ? 'loading' : '')} title="Save PDF">
      {show && (
        <PDFDownloadLink
          document={<InvoicePDF {...invoiceData}/>}
          fileName={`${invoiceData.invoiceTitle ? invoiceData.invoiceTitle.toLowerCase() : 'invoice'}.pdf`}
          aria-label="Save PDF"
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : <Button variant="contained">Download PDF</Button>
          }
        </PDFDownloadLink>
      )}
    </div>
  );
}

export default Download
