import { Image as PdfImage } from "@react-pdf/renderer";
import { Image as NormalImage } from "mui-image";


function EditableText(props){
    let component;
    if(props.isPDF){
        component = <PdfImage src="/CONSILIO.png"/>
    }else{
        component = <NormalImage src="/CONSILIO.png"/>
    }

    return (
        component
    );
}

export default EditableText;