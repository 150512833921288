import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './Main';
import CssBaseline from '@mui/material/CssBaseline';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <CssBaseline enableColorScheme/>
    <Main />
  </React.StrictMode>
);
reportWebVitals();
