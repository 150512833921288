import * as React from 'react';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Icon from '@mui/material/Icon';

export const mainListItems = (
  <React.Fragment>
    <NavLink to='/dashboard'>
        <ListItemButton>
        <ListItemIcon>
        <Icon>dashboard</Icon>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
        </ListItemButton>
    </NavLink>
    <NavLink to='invoices'>
        <ListItemButton>
        <ListItemIcon>
            <Icon>table_view</Icon>
        </ListItemIcon>
        <ListItemText primary="Invoices" />
        </ListItemButton>
    </NavLink>
    
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Coming soon
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <Icon>build</Icon>
      </ListItemIcon>
      <ListItemText primary="In development" />
    </ListItemButton>
  </React.Fragment>
);