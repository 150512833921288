import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import "./App.css";
import Login from "./login/Login";
import Dashboard from "./dashboard/Dashboard";
import NotFound from "./NotFound";
import Invoices from "./invoices/Invoice";

const AuthProviderWithCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

function Main() {
  return (
    <BrowserRouter className="main">
      <AuthProviderWithCallback
        domain="cozzy-fungi.eu.auth0.com"
        clientId="VH0gGxik67Fd7uhnleeWLeaEWBME1VTB"
        redirectUri={window.location.origin}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard/*" element={<Dashboard />}>
            <Route path="invoices" element={<Invoices />} />
            <Route path="panel" element={<Invoices />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProviderWithCallback>
    </BrowserRouter>
  );
}

export default Main;
