import {
  Unstable_Grid2 as Grid,
  Stack,
  Button,
  TextareaAutosize,
  Typography,
  Alert,
} from "@mui/material/";
import Download from "./components/DownloadPDF";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  IconButton,
  Icon,
} from "@mui/material";
import EditableText from "./components/EditableText";
import EditableCheck from "./components/EditableCheck";
import { useReducer } from "react";
import {
  UPDATE_BLANK_ITEM,
  UPDATE_ITEMS,
  CLEAR_BLANK_ITEM,
  onInputChange,
  onItemAdded,
  onItemDeleted,
} from "./hooks/formUtils";

import Image from "./components/Image";

const titleSeparator = "$KIA$";
const dateNow = new Date();

function getDateParsed() {
  return dateNow.toLocaleDateString(
    {},
    { timeZone: "CET", day: "2-digit", month: "long", year: "numeric" }
  );
}

const invoiceInitState = {
  invoiceTitle: "",
  datosFactura: {
    numero: {
      fieldId: "",
      fieldLabel: "Número de factura",
      fieldValue: undefined,
      dFieldValue: undefined,
    },
    fecha: {
      fieldId: "fechaFactura",
      fieldLabel: "Fecha",
      fieldValue: undefined,
      dFieldValue: getDateParsed(),
    },
    cifNif: {
      fieldId: "",
      fieldLabel: "CIF/NIF",
      fieldValue: undefined,
      dFieldValue: "B-97631972",
    },
    referencia: {
      fieldId: "",
      fieldLabel: "REF",
      fieldValue: undefined,
      dFieldValue: "MAXCOLCHON NIGRAN",
    },
  },
  datosEmpresa: {
    nombre: {
      fieldId: "",
      fieldLabel: "Nombre Empresa",
      fieldValue: undefined,
      dFieldValue: "MAXCOLCHON SL",
    },
    calle: {
      fieldId: "fechaFactura",
      fieldLabel: "Calle",
      fieldValue: undefined,
      dFieldValue: "SIROCO 17 BAJO",
    },
    calle2: {
      fieldId: "",
      fieldLabel: "Via",
      fieldValue: undefined,
      dFieldValue: "POL. IND. LOS VIENTOS",
    },
    provincia: {
      fieldId: "",
      fieldLabel: "Localidad",
      fieldValue: undefined,
      dFieldValue: "46119 NÁQUERA VALENCIA",
    },
  },
  items: [],
  isPDF: false,
};

const initBlankState = {
  units: {
    fieldId: "units",
    fieldLabel: "Unidades",
    value: "",
    dFieldValue: undefined,
  },
  description: {
    fieldId: "description",
    fieldLabel: "Descripcion",
    value: "",
    dFieldValue: undefined,
  },
  unit_type: {
    fieldId: "unit_type",
    fieldLabel: "Tipo de unidad",
    value: "",
    dFieldValue: undefined,
  },
  included: {
    fieldId: "included",
    fieldLabel: "Incluido",
    fieldValue: "f",
    hasError: false,
    touched: false,
    value: "f",
    dFieldValue: undefined,
  },
  unit_price: {
    fieldId: "unit_price",
    fieldLabel: "Precio unidad",
    value: "",
    dFieldValue: undefined,
  },
  isFormValid: false,
};

const updateItemsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ITEMS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

function InvoiceTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Cantidad</TableCell>
            <TableCell align="left">Descripción</TableCell>
            <TableCell align="left">Unidad de medida</TableCell>
            <TableCell align="center">M. Incl.</TableCell>
            <TableCell align="center">P. Unitario</TableCell>
            <TableCell align="center">T. Importe</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.invoiceState !== undefined &&
          props.invoiceState.items !== undefined &&
          props.invoiceState.items.length > 0
            ? props.invoiceState.items.map((row) => {
                return (
                  <InvoiceTableItem
                    key={row.itemID}
                    align="center"
                    width="100%"
                    item={row}
                    invoiceState={props.invoiceState}
                    dispatch={props.dispatchInvoiceUpdateItem}
                  />
                );
              })
            : undefined}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function InvoiceTableItem(props) {
  return (
    <TableRow key={props.item.itemID}>
      <TableCell align="left">{props.item.units}</TableCell>
      <TableCell align="left">{props.item.description}</TableCell>
      <TableCell align="left">{props.item.unit_type}</TableCell>
      {props.item.included === "t" ? (
        <TableCell align="center">
          <Icon>check</Icon>
        </TableCell>
      ) : (
        <TableCell align="center">
          <Icon>close</Icon>
        </TableCell>
      )}
      <TableCell align="center">{props.item.unit_price}</TableCell>
      <TableCell align="center">
        {props.item.total}
      </TableCell>
      <TableCell align="center">
        <IconButton
          color="error"
          size="small"
          onClick={(event) =>
            onItemDeleted(props.item.itemID, props.dispatch, props.invoiceState)
          }
        >
          <Icon>delete</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const itemBlankStateReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_BLANK_ITEM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };

    case CLEAR_BLANK_ITEM:
      return { ...state };

    default:
      return state;
  }
};

function InvoiceItemAdd(props) {
  const [itemBlankState, dispatchBlankItem] = useReducer(
    itemBlankStateReducer,
    initBlankState
  );

  return (
    <Grid xs={12} display="flex" justifyContent={"space-evenly"} gap={5}>
      <EditableText
        data={itemBlankState.units}
        isPDF={props.isPDF}
        onChange={(e) => {
          onInputChange(
            "units",
            e.target.value,
            dispatchBlankItem,
            itemBlankState
          );
        }}
      />
      <EditableText
        data={itemBlankState.description}
        isPDF={props.isPDF}
        onChange={(e) => {
          onInputChange(
            "description",
            e.target.value,
            dispatchBlankItem,
            itemBlankState
          );
        }}
      />
      <EditableText
        data={itemBlankState.unit_type}
        isPDF={props.isPDF}
        onChange={(e) => {
          onInputChange(
            "unit_type",
            e.target.value,
            dispatchBlankItem,
            itemBlankState
          );
        }}
      />
      <FormGroup>
        <FormControlLabel
          label={itemBlankState.included.fieldLabel}
          control={
            <EditableCheck
              data={itemBlankState.included}
              isPDF={props.isPDF}
              onChange={(e) => {
                onInputChange(
                  "included",
                  e.target.checked ? "t" : "f",
                  dispatchBlankItem,
                  itemBlankState
                );
              }}
            />
          }
        />
      </FormGroup>

      <EditableText
        data={itemBlankState.unit_price}
        isPDF={props.isPDF}
        onChange={(e) => {
          onInputChange(
            "unit_price",
            e.target.value,
            dispatchBlankItem,
            itemBlankState
          );
        }}
      />
      <Button
        variant="contained"
        onClick={(event) =>
          onItemAdded(
            props.dispatchInvoiceUpdateItem,
            dispatchBlankItem,
            props.invoiceState,
            itemBlankState
          )
        }
      >
        Add
      </Button>
    </Grid>
  );
}

function InvoicePage(props) {
  const [invoiceState, dispatchItemUpdate] = useReducer(
    updateItemsReducer,
    invoiceInitState
  );

  return (
    <Grid container spacing={5}>
      <Grid xs={6} display="flex" flexDirection="column">
        <Image />
      </Grid>
      <Grid
        xs={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <EditableText
          data={props.invoice.datosFactura.numero}
          isPDF={props.invoice.isPDF}
        />
      </Grid>
      <Grid xs={6} display="flex" alignItems="center" flexDirection="column">
        <EditableText
          data={props.invoice.datosEmpresa.nombre}
          isPDF={props.invoice.isPDF}
        />
        <EditableText
          data={props.invoice.datosEmpresa.calle}
          isPDF={props.invoice.isPDF}
        />
        <EditableText
          data={props.invoice.datosEmpresa.calle2}
          isPDF={props.invoice.isPDF}
        />
        <EditableText
          data={props.invoice.datosEmpresa.provincia}
          isPDF={props.invoice.isPDF}
        />
      </Grid>
      <Grid
        xs={6}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="end"
      >
        <EditableText
          data={props.invoice.datosFactura.fecha}
          isPDF={props.invoice.isPDF}
        />
        <EditableText
          data={props.invoice.datosFactura.cifNif}
          isPDF={props.invoice.isPDF}
        />
        <EditableText
          data={props.invoice.datosFactura.referencia}
          isPDF={props.invoice.isPDF}
        />
      </Grid>
      <Grid xs={12}>
        <InvoiceItemAdd
          isPDF={props.invoice.isPDF}
          invoiceState={invoiceState}
          dispatchInvoiceUpdateItem={dispatchItemUpdate}
        />
        <InvoiceTable
          invoiceState={invoiceState}
          dispatchInvoiceUpdateItem={dispatchItemUpdate}
        />
      </Grid>
      <Stack direction="row" spacing={2}>
        <Button
          xs={{ float: "right" }}
          variant="outlined"
          onClick={(event) => {
            invoiceState.items.length = 0;
            dispatchItemUpdate({
              type: UPDATE_ITEMS,
              ...invoiceState,
            });
          }}
        >
          Clear table
        </Button>
        {
          invoiceState.items.length>0?
        <Download invoiceState={invoiceState} />:undefined
        }
      </Stack>
    </Grid>
  );
}

function InvoiceDocument() {
  return (
    <Grid container spacing={5} className="invoice">
      <Grid xs={12}>
        <InvoicePage invoice={invoiceInitState} />
      </Grid>
    </Grid>
  );
}

function Invoices() {
  invoiceInitState.invoiceTitle =
   Date.now() + "    " +
    Intl.DateTimeFormat("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(Date.now());

  return (
    <div className="Invoice">
      <InvoiceDocument />
    </div>
  );
}

export { InvoicePage };
export default Invoices;
