import * as React from 'react';
import Button from '@mui/material/Button';
import Image from 'mui-image';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react";




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Bloom Digital Editions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image
            src='./bloom-logo-black.svg'
            height='80%'
            width='100%'
            sx={{mt:20, mb:5}}
            />
            
            <Button
              onClick={() => loginWithRedirect()}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
            >Sign in
            </Button>
        </Box>
        <Copyright sx={{ mt: 2, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}