import { roundToTwo } from "../hooks/formUtils";
import HeeboRegular from "../../fonts/Heebo-Regular.ttf";
import HeeboBold from "../../fonts/Heebo-Bold.ttf";

import {
  Document,
  Page,
  Link,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";

import EditableText from "./EditableText";

Font.register({
  family: "Heebo",
  fonts: [
    {
      src: HeeboRegular,
      fontWeight: 400,
    },
    {
      src: HeeboBold,
      fontWeight: 700,
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontSize: 9,
    display: "flex",
    flexGrow: "1",
    flexDirection: "column",
    fontFamily:"Heebo"
  },

  headerConsilio: {
    display: "flex",
    flexDirection: "column",
  },

  bodyConsilio: {
    display: "flex",
    flexGrow: "1",
    flexDirection: "column",
    marginTop: "20px",
  },

  tableMargin: {
    marginLeft: "60px",
    marginRight: "60px",
  },
  cellPadding: { padding: "3px" },
  borderTop: {
    borderTop: "1px solid black",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  borderLeft: {
    borderLeft: "1px solid black",
  },
  borderTable: {
    border: "1px solid black",
  },
  bgcolorGreen: {
    backgroundColor: "#92d050",
  },

  width8: { width: "8%" },
  width16: { width: "16%" },
  width36: { width: "36%" },
  width52: { width: "52%" },

  tAlCenter: { textAlign: "center" },

  rowDisplay: {
    display: "flex",
    flexDirection: "row",
  },
});

function InvoicePDF(props) {
  let isPDF = true;
  const invoiceData = { ...props };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.headerConsilio, styles.tableMargin]}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "1",
              flexWrap: "nowrap",
            }}
          >
            <Image src="/CONSILIO.png" style={{ width: "400px" }} />
            <View>
              <Text
                style={{
                  margin: "auto",
                  fontSize: "13px",
                  paddingTop: "40px",
                  fontWeight:700,
                }}
              >
                FACTURA
              </Text>
              
            </View>
          </View>

          <View
            style={[{
              display: "flex",
              flexDirection: "row",
              flexGrow: "1",
              flexWrap: "nowrap",
              marginTop:"20px",
              
            }]}
          >
            <View style={{ textAlign: "left", flexGrow: "1" }}>
              <Text>
              FACTURA N.º: <Text style={{ textAlign: "right"}}>{invoiceData.invoiceTitle.split(" ")[0]}</Text>
              </Text>
              <Text>
                {invoiceData.invoiceTitle.split(" ")[4]}
              </Text>
              <EditableText
                data={invoiceData.datosFactura.cifNif}
                isPDF={isPDF}
              />
              <EditableText
                data={invoiceData.datosFactura.referencia}
                isPDF={isPDF}
              />
            </View>
            <View style={{ alignItems:"flex-end", flexGrow: "1" }}>
              <EditableText
                style={{  fontWeight:700}}
                data={invoiceData.datosEmpresa.nombre}
                isPDF={isPDF}
              />
              <EditableText
                style={{  fontWeight:700}}
                data={invoiceData.datosEmpresa.calle}
                isPDF={isPDF}
              />
              <EditableText
                style={{  fontWeight:700}}
                data={invoiceData.datosEmpresa.calle2}
                isPDF={isPDF}
              />
              <EditableText
                style={{  fontWeight:700}}
                data={invoiceData.datosEmpresa.provincia}
                isPDF={isPDF}
              />
            </View>
          </View>
        </View>
        <View style={[styles.bodyConsilio, styles.tableMargin]}>
          <View>
            <Table items={invoiceData.items} />
          </View>
        </View>

        <View
          style={[
            styles.tableMargin,
            styles.borderTable,
            { marginTop: "30px" },
          ]}
        >
          <View
            style={[
              styles.borderBottom,
              { textAlign: "left", padding: "10px" },
            ]}
          >
            <Text>Fecha de vencimiento: Inmediata</Text>
          </View>
          <View style={[{ textAlign: "center", padding: "10px" }]}>
            <Text style={{ textAlign: "left"}}>Observaciones:</Text>
            <Text>
              Pago mediante talón nominativo a Consitio S.L. o ingreso en
              cuenta:
            </Text>
            <Text>IBAN:ES69 2100 4116 1922 0017 5039 CAIXA.</Text>
          </View>
        </View>
        <View
          style={[
            {
              display: "flex",
              flexGrow: "1",
              flexDirection: "column",
              textAlign: "center",
              marginTop: "30px",
            },
          ]}
        >
          <Text style={{ fontSize: "8px" }}>
            C/ GORRION 4 -28946 FUENLABRADA –MADRID C.I.F: B-87535621
          </Text>
          <Text style={{ fontSize: "8px" }}>Móvil: 606 898 532</Text>
          <Text style={{ fontSize: "8px" }}>
            E-mail:{" "}
            <Link src="mailto:consitio.sl@gmail.com">
              <Text>consitio.sl@gmail.com</Text>
            </Link>
          </Text>
          <Text style={{ fontSize: "6px" }}>
            Inscrita en el Registro Mercantil de Madrid, Tomo 34720, Libro 0,
            Folio 35 Sección 8ª, Hoja M-624465, Inscripción 1ª.
          </Text>
        </View>
      </Page>
    </Document>
  );
}

const Table = ({ items }) => {
  let base = 0;
  const IVA = 0.21;

  for (const key in items) {
    base += items[key].total;
  }

  return (
    <View>
      <View
        style={[
          styles.bgcolorGreen,
          styles.rowDisplay,
          styles.tAlCenter,
          styles.borderTop,
        ]}
      >
        <Text
          style={[
            styles.width16,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
            styles.borderLeft,
          ]}
        >
          Cantidad
        </Text>
        <Text
          style={[
            styles.width36,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          Descripción
        </Text>
        <Text
          style={[
            styles.width8,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          U.M.
        </Text>
        <Text
          style={[
            styles.width8,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          M.incl
        </Text>
        <Text
          style={[
            styles.width16,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          P. Unitario
        </Text>
        <Text
          style={[
            styles.width16,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          T. Importe
        </Text>
      </View>
      {items !== undefined && items.length > 0
        ? items.map((row) => {
            return InvoiceItemRow(row);
          })
        : undefined}
      <View style={[styles.bgcolorGreen, styles.rowDisplay, styles.tAlCenter]}>
        <Text
          style={[
            styles.width52,
            styles.cellPadding,
            styles.borderRight,
            styles.borderLeft,
            styles.borderBottom,
          ]}
        >
          BASE IMPONIBLE
        </Text>
        <Text
          style={[
            styles.width16,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          % IVA
        </Text>
        <Text
          style={[
            styles.width16,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          IMPORTE IVA
        </Text>
        <Text
          style={[
            styles.width16,
            styles.cellPadding,
            styles.borderRight,
            styles.borderBottom,
          ]}
        >
          TOTAL
        </Text>
      </View>
      <View style={[styles.rowDisplay, styles.tAlCenter, styles.borderBottom]}>
        <Text
          style={[
            styles.width52,
            styles.cellPadding,
            styles.borderRight,
            styles.borderLeft,
          ]}
        >
          {base} €
        </Text>
        <Text style={[styles.width16, styles.cellPadding, styles.borderRight]}>
          21%
        </Text>
        <Text style={[styles.width16, styles.cellPadding, styles.borderRight]}>
          {roundToTwo(base * IVA)} €
        </Text>
        <Text style={[styles.width16, styles.cellPadding, styles.borderRight]}>
          {roundToTwo(base * IVA + base)} €
        </Text>
      </View>
    </View>
  );
};

const InvoiceItemRow = (props) => {
  console.log(props);
  return (
    props.units!==""?
    <View
      key={props.itemID}
      style={[
        styles.rowDisplay,
        styles.tAlCenter,
        styles.borderLeft,
        styles.borderRight,
      ]}
    >
      <Text
        style={[
          styles.width16,
          styles.cellPadding,
          styles.borderRight,
          styles.borderBottom,
        ]}
      >
        {props.units}
      </Text>
      <Text
        style={[
          styles.width36,
          styles.cellPadding,
          styles.borderRight,
          styles.borderBottom,
        ]}
      >
        {props.description}
      </Text>
      <Text
        style={[
          styles.width8,
          styles.cellPadding,
          styles.borderRight,
          styles.borderBottom,
        ]}
      >
        {props.unit_type}
      </Text>
      <Text
        style={[
          styles.width8,
          styles.cellPadding,
          styles.borderRight,
          styles.borderBottom,
        ]}
      >
        {props.included === "t" ? "X" : ""}
      </Text>
      <Text
        style={[
          styles.width16,
          styles.cellPadding,
          styles.borderRight,
          styles.borderBottom,
        ]}
      >
        {props.unit_price} €
      </Text>
      <Text style={[styles.width16, styles.cellPadding, styles.borderBottom]}>
        {props.total} €
      </Text>
    </View>:<View
    key={props.itemID}
    style={[
      styles.rowDisplay,
      styles.tAlCenter,
      styles.borderLeft,
      styles.borderRight,
    ]}>
    <Text
        style={[
          {width:"100%", fontWeight:700},
          styles.cellPadding,
          styles.borderBottom,
        ]}
      >
        {props.description}
      </Text>
    </View>
  );
};

export default InvoicePDF;
