export const UPDATE_BLANK_ITEM = "UPDATE_BLANK_ITEM";
export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const CLEAR_BLANK_ITEM = "CLEAR_BLANK_ITEM";
/**
 
 * Triggered only when the item is inserted in the table. 
 
 */

export const onItemAdded = (
  dispatchInvoice,
  dispatchBlankItem,
  invoiceState,
  itemBlankState
) => {
  let isFormValid = true;

  for (const key in itemBlankState) {
    if (
      (itemBlankState[key].hasError ||
        itemBlankState[key].hasError === undefined) &&
      key !== "isFormValid" && itemBlankState[key].fieldId === "description"
    ){
      isFormValid = false;
      break;
    }else if(itemBlankState[key]==='unit_price' && isNaN(itemBlankState[key].value)){
      itemBlankState[key].value=0;
    }
  }

  if (itemBlankState.isFormValid && isFormValid) {
    invoiceState.items.push(
      createDataItem(
        "ITEM_" + Date.now(),
        itemBlankState.units.value.trim(),
        itemBlankState.description.value.trim(),
        itemBlankState.unit_type.value.trim(),
        itemBlankState.included.value.trim(),
        roundToTwo(itemBlankState.unit_price.value),
        roundToTwo(itemBlankState.units.value * itemBlankState.unit_price.value)
      )
    );

    dispatchInvoice({
      type: UPDATE_ITEMS,
      data: { ...invoiceState },
    });

    for (const key in itemBlankState) {
      if (key !== "isFormValid") {
        if (key === "included") {
          itemBlankState[key].value = "f";
          itemBlankState[key].hasError = false;
        } else {
          itemBlankState[key].hasError = undefined;
          itemBlankState[key].value = "";
        }
      }
    }

    dispatchBlankItem({
      type: CLEAR_BLANK_ITEM,
      itemBlankState: { ...itemBlankState },
    });
  }
};

function createDataItem(
  itemID,
  units,
  description,
  unit_type,
  included,
  unit_price,
  total
) {
  return { itemID, units, description, unit_type, included, unit_price, total };
}

/**
 
 * Triggered only when the item is eliminated in the table. 
 
 */

export const onItemDeleted = (itemID, dispatch, invoiceState) => {
  if (invoiceState.items.length > 0) {
    invoiceState.items.filter((item, index, arr) => {
      if (item.itemID === itemID) {
        arr.splice(index, 1);
        return true;
      }
      return false;
    });

    dispatch({
      type: UPDATE_ITEMS,
      data: { ...invoiceState },
    });
  } else {
  }
};

/**

 * Triggered every time the value of the form changes

 */

export const onInputChange = (name, value, dispatch, formState) => {
  const { hasError, error } = validateInputObligatory(name, value);
  let isFormValid = true;

  for (const key in formState) {
    if (
      (formState[key].hasError || formState[key].hasError === undefined) &&
      key !== "isFormValid" &&
      key !== "included" && key!==name &&
      hasError
    ) {
      isFormValid = false;
      break;
    }
  }

  dispatch({
    type: UPDATE_BLANK_ITEM,
    data: {
      name,
      value,
      hasError,
      error,
      touched: false /*  */,
      isFormValid,
    },
  });
};

export const validateInputObligatory = (name, value) => {
  
  let { hasError, error } = isEmptyString(value);
  if (!hasError || name!=="description") {
    switch (name) {
      case "units":
        if (!/^\d*$/.test(value)) {
          hasError = true;
          error = "Hay carácteres inválidos";
        }else{
          hasError=false;
          error = "";
        }
        break;
      case "description":
        break;
      case "unit_type":
        hasError = false;
        error = "";
        break;
      case "unit_price":
        if (!/^-?[0-9,.]*$/.test(value)) {
          hasError = true;
          error = "Hay carácteres inválidos";
        }else{
          hasError = false;
          error = "";
        }
        break;
      default:
        break;
    }
  }

  return { hasError, error };
};

function isEmptyString(value) {
  let hasError = false,
    error = "";

  if (value.trim() === "") {
    hasError = true;
    error = "El campo no puede estar vacío";
  }

  return { hasError, error };
}

export function roundToTwo(num) {
  return +(Math.round(num + "e+2")  + "e-2");
}