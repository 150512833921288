import { TextField } from "@mui/material";
import { Text } from "@react-pdf/renderer";

function EditableText(props) {
  let component;
  if (props.isPDF) {
    component = (
      <Text style={{...props.style}}>
        {props.data.value !== undefined && props.data.value.trim().length > 0
          ? props.data.value
          : props.data.dFieldValue}
      </Text>
    );
  } else {
    component = (
      <TextField
        onChange={props.onChange}
        error={props.data.hasError}
        helperText={props.data.error}
        fullWidth
        id={props.data.fieldId}
        label={props.data.fieldLabel}
        variant="standard"
        value={props.data.value}
        defaultValue={props.data.dFieldValue}
      />
    );
  }

  return component;
}

export default EditableText;
