import Typography from '@mui/material/Typography';

function NotFound(){

    return(
    <div className="notFound">
        <Typography
                component="h1"
                variant="h1"
                color="rgb(0,0,0)"
                sx={{ flexGrow: 1, textAlign:'center' }}
              >
                404<br/> This page doesn't exist
              </Typography>
    </div>
    );
}

export default NotFound;